$site-logo-size: $main-nav-height;
$site-logo-size-fixed: $main-nav-height-fixed;

#sn-site-logo {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 0 - $maxWidth/2;
  width: $site-logo-size + 125;
  height: $site-logo-size;
  margin-top: $site-tagline-height + 1;
  padding: 8px;
  background-color: transparent;
  z-index: 1000;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all $transition-linear;

  .nav-fixed & {
    width: $site-logo-size-fixed + 30;
    height: $site-logo-size-fixed;
    margin-top: 0;

    .sn-site-logo-background { background-position: center center; }

  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-background-size: contain;
    background-position: center 3px;
  }
}
