.user_mode {

  .sponsorScroller {

    .column-1 {
      width: 100%;
      height: 120px;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      font-size: 0;
     }

     .layoutInner { max-width: 1235px; }

  }

  .layoutContainer {

    &.layout-100.sponsorScroller {
      background: $accent-color;
      margin: 0;
      position: relative;
      padding: 0;
    }

    &.sponsorScroller .pageEl {
      display: inline-block;
      width: 215px;
      text-align: center;
      padding: 0 10px;
      vertical-align: middle;
      box-sizing: border-box;
      padding: 0;
      margin: 0 10px;
      transition: $transition-standard;
      background: white;

      &:hover {

        .pageElement.heroPhotoElement img {
          opacity: .75;
        }

      }

      .pageElement.heroPhotoElement {
        overflow: hidden;
        margin: 0;

        img {
          height: auto;
          transition: $transition-standard;

        }

      }

    }

  }

}

#sponsorScrollerGroup {
    position: relative;
    left: 0;
    padding: 17px 0;
    margin: 0 21px;
    height: 120px;
}

.sponsorScroller .column-1  {
    text-align: center;
}

#sponsorScrollerPrev,
#sponsorScrollerNext {
    display: none;
    width: 35px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: background-color 0.5s ease;
    background: $accent-color url('http://cdn2.sportngin.com/attachments/photo/6207/3489/arrows_scroll_large.png') center no-repeat;
    background-size: 10px auto;
}

#sponsorScrollerNext {
    left: auto;
    right: 0;
    border-right: none;
    background: $accent-color url('http://cdn1.sportngin.com/attachments/photo/6207/3838/arrows_scroll_right_large.png') center no-repeat;
    background-size: 10px auto;
}

.scrollArrowsUsl { display: none; }

#sponsorBarContainer { border-top: 2px solid $border-color; }

.edit_mode #sponsorBarContainer { display: none; }

/* Scales down logos in edit mode so they're easier to manage */

.edit_mode .layoutContainer.sponsorScroller div.drag {
    display: inline-block;
    width: 33%;
}
