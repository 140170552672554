// Home Page
.user_mode#home {

	.theme-sub-nav { display: none; }

	#topNavPlaceholder { height: 132px; }

	#panelTwo {
		padding-left: 0;
		padding-right: 0;
	}

	.aggHeader,
	.pollElement > h3,
	.textBlockElement > h3,
	.tableElement > h3 {
		margin-top: -15px;
 	 	margin-left: -15px;
  		width: calc(100% + 30px);
	}

	.gray .layoutContainer .pageElement.tabbedElement { margin-bottom: 15px; }

	.layoutContainer .layoutContainer{ 
		padding-bottom: 0; 
		padding-left: 0;
	}

}

// Inner Layout & custom layout conatiners
.user_mode {

	.layoutInner { @include displayCenter($maxWidth); }	

	.full-width {
		padding-left: 0;

		.layoutInner { 
			max-width: 100%; 

			.pageElement { margin: 0; }

		}

		.column {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.newsSlideShow-container { margin-bottom: 0; }

	}

	.gray { 
		background: $bg-gray; 
		padding: 30px 0;
		margin: 0 0 30px;

		&.nm {
			margin-bottom: 0;
		}

		.pageElement{ 
			background: white;
			padding: 15px; 
			border: solid 1px $border-color;

			&.linkElement { 
				padding: 0; 
				border: none;
			}

			&.cta { padding: 0; }

		}

		// Gray Tab Element Styles
		.tabbedElement {
			.tabContainer {
				.pageElement { border: none; }
			}
		}

	}

}

// Table Element header styles
.tableElement {

	h3 { 
		@include special-header;
		margin-bottom: $site-padding;

		span:before { top: calc(50% - 1px); }

	}

}

// Header connect
#headerConnect {
	width: 100%;
	padding: 1px 0;
	position: absolute;
	top: 0;

	.sn-social-media-list {
		position: absolute;
		right: 0;
		display: inline-block;

		.sn-social-media-icon {
			float: left;
		}

		&.light .sn-social-media-icon {
			background: transparent;
			margin: 0;

			&:before { transition: opacity .2s ease-in-out; }

			&:hover:before { opacity: .75; }

		}

	}

}

// Add RSS icon to social media
.sn-social-media-list {

	.icon-link:before { content: '\f09e'; }

}	

// Top Nav
#topNav {

	.theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item[data-nav-url="http://www.iwlca.org.prod.sportngin.com/page/nav/2508980"] {
		display: none !important;
	}

	.edit_mode & {

		.theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item[data-nav-url="http://www.iwlca.org.prod.sportngin.com/page/nav/2508980"] {
			display: table !important;
		}

	}
		
}

// Sub Nav
.theme-sub-nav .theme-nav-style-dropdown {
	max-width: 1000px !important;
    left: 0 !important;
}

// Org Title
#orgTitle {
  display: none;
  width: 60%;
  height: 44px;
  line-height: 46px;
  margin-left: 35px;
  color: white;
  font-family: $fontOne;
  letter-spacing: 1px;
}

// Slidehsow image top 0
.feature {
	.newsSlideShow-article img {
		top: 0 !important;
	}
}	

// Media Slider Title Sub-Text
.pageElement.mediaSlider {

	p {
		font-family: $fontTwo;
		font-size: 12px;
		color: $accent-color;
	}

}

// Breakpoints
@media only screen and (max-width: 1024px){

	#orgTitle { display: inline-block !important; }

}

// FB widget fix 
.layoutInner {

	.pageElement {

		.fb_iframe_widget:nth-of-type(2){
			display: none;
		} 

	}
}















