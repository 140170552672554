/* Poll Element Styles */

// All Styles
.pollElement {
	h3 {
		@include special-header;
	}
	ul li {
		list-style-type: none;
		padding: 8px 8px 8px 10px;
		margin:0;
		&:first-child{
  		padding:15px 8px 8px 10px;
		}
		&:nth-last-child(2){
  		padding:8px 8px 15px 10px;
		}
	}
	label,
	h5 {
		font-size:rem(14);
		font-weight: 400;
		color:$bodyCopyColorTwo;
	}
	h5 {
		text-transform:none;
	}
	label {
		padding-left: 10px;
		vertical-align: middle;
	}
	ul li.pollTotal {
  	padding:15px 8px 8px 10px;
		border-top: $borderWidth solid $borderColor;
		h5{
  		font-weight:600;
		}
	}
	ul li.voteButton {
  	position:relative;
		display: block;
		text-align: center;
		border-bottom: none;
		padding:0;
		max-width:320px;
    &:hover{
      &:after{
        color:#fff;
      }
    }
	}
	ul li.voteButton input[type='submit'] {
		width: 100%;
		@include special-link;
		font-family: $fontOne; 
	}
}
.barBackground p {
  white-space: nowrap;
  margin-bottom:0;
  font-size:rem(18);
  color:$bodyCopyColorOne;
}
